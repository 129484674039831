<template>
  <div class='form-wrap'>
    <form class='register'>
      <p class='login-register'>
        Already have an account?
        <router-link class='router-link' :to="{ name: 'Login' }">Login</router-link>
      </p>
      <h2>Create Your TecnoBlog Account</h2>
      <div class='inputs'>
        <div class='input'>
          <input type='text' placeholder='First Name' v-model='firstName' />
          <user class='icon' />
        </div>
        <div class='input'>
          <input type='text' placeholder='Last Name' v-model='lastName' />
          <user class='icon' />
        </div>
        <div class='input'>
          <input type='text' placeholder='Username' v-model='username' />
          <user class='icon' />
        </div>
        <div class='input'>
          <input type='text' placeholder='Email' v-model='email' />
          <email class='icon' />
        </div>
        <div class='input'>
          <input type='password' placeholder='Password' v-model='password' />
          <password class='icon' />
        </div>
        <div v-show='error' class='error'>{{ this.errorMsg }}</div>
      </div>
      <button @click.prevent='register'>Registrar</button>
      <div class='angle'></div>
    </form>
    <div class='background'></div>
  </div>
</template>

<script>
import email from '../../assets/icons/envelope-regular.svg'
import password from '../../assets/icons/lock-alt-solid.svg'
import user from '../../assets/icons/user-alt-light.svg'
import firebase from 'firebase/compat/app'
import db from '../../firebase/firebaseinit.js'
// import 'firebase/compat/auth'
// const firebase = () => import('firebase/compat/app')
// Promise.all([]).then(import('firebase/compat/auth'))

export default {
  name: 'Register',
  components: {
    email,
    password,
    user
  },
  data () {
    return {
      firstName: '',
      lastName: '',
      username: '',
      email: '',
      password: '',
      error: null,
      errorMsg: ''
    }
  },
  methods: {
    async register () {
      if (
        this.email !== '' &&
        this.password !== '' &&
        this.firstName !== '' &&
        this.lastName !== '' &&
        this.username !== ''
      ) {
        this.error = false
        this.errorMsg = ''
        const firebaseAuth = await firebase.auth()
        const createUser = await firebaseAuth.createUserWithEmailAndPassword(this.email, this.password)
        const result = await createUser
        const dataBase = db.collection('users').doc(result.user.uid)
        await dataBase.set({
          firstName: this.firstName,
          lastName: this.lastName,
          username: this.username,
          email: this.email
        })
        this.$router.push({ name: 'Home' })
        return
      }
      this.error = true
      this.errorMsg = 'Please fill out all the fields!'
    }
  }
}
</script>

<style lang='scss' scoped>
.register {
  h2 {
    max-width: 350px
  }
}
</style>
